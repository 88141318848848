import React from 'react';
import { Chip, Box, Button, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ClearIcon from '@mui/icons-material/Clear';

const WordsContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.light}`,
  backgroundColor: theme.palette.background.paper,
}));

const WordsWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  flexGrow: 1,
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));

const LastPlayedWords = ({ words, onPlay, onClear }) => {
  return (
    <WordsContainer elevation={3}>
      <WordsWrapper>
        {words.map((word, index) => (
          <Chip key={index} label={word} variant="outlined" />
        ))}
      </WordsWrapper>
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlayArrowIcon />}
          onClick={onPlay}
          disabled={words.length === 0}
        >
          Play
        </Button>
        <IconButton
          color="secondary"
          onClick={onClear}
          disabled={words.length === 0}
        >
          <ClearIcon />
        </IconButton>
      </ButtonContainer>
    </WordsContainer>
  );
};

export default LastPlayedWords;
