import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaBars, FaPlus } from 'react-icons/fa';
import { speak } from './utils/tts';
import LoginPage from './components/LoginPage';
import Tile from './components/Tile';
import { LibraryProvider } from './contexts/LibraryContext';
import ErrorBoundary from './components/ErrorBoundary';
import SettingsMenu from './components/SettingsMenu';
import Modal from './components/Modal';
import ConfirmModal from './components/ConfirmModal';
import LibraryModal from './components/LibraryModal';
import LastPlayedWords from './components/LastPlayedWords';
import CategoryButtons from './components/CategoryButtons';
import AddTileModal from './components/AddTileModal';
import TileGrid from './components/TileGrid';
import { useAppState } from './hooks/useAppState';
import { usePersistentTiles } from './hooks/usePersistentTiles';
import { useErrorHandler } from './hooks/useErrorHandler';
import { handleAsyncError } from './utils/errorHandling';
import { useNotification } from './contexts/NotificationContext';
import './App.css';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

// Add a loading context
const LoadingContext = React.createContext();

// Add this component
const LoadingOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;
function App() {
  const { loginWithRedirect, logout, user } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('all');
  const [activeTileType, setActiveTileType] = useState('default');
  const [tiles, setTiles] = useState([]);
  const [lastPlayedWords, setLastPlayedWords] = useState([]);
  const [defaultTiles, setDefaultTiles] = useState([
    { id: 1, title: 'Play', image: '/images/play.webp' },
    { id: 2, title: 'Mam', image: '/images/mam.webp' },
    { id: 3, title: 'Dad', image: '/images/dad.webp' },
    { id: 4, title: 'Love', image: '/images/love.webp' },
    { id: 5, title: 'Food', image: '/images/food.webp' },
    { id: 6, title: 'More', image: '/images/more.webp' },
    { id: 7, title: 'Drink', image: '/images/drink.webp' },
    { id: 8, title: 'Sleep', image: '/images/sleep.webp' },
    { id: 9, title: 'Help', image: '/images/help.webp' },
    { id: 10, title: 'Happy', image: '/images/happy.webp' },
  ]);

  const [currentTile, setCurrentTile] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [tileToDelete, setTileToDelete] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [tileToEdit, setTileToEdit] = useState(null);

  const [userSettings, setUserSettings] = useState({
    gridColumns: 4,
    theme: 'light',
    voiceGender: 'male'  // Add this line
  });

  const categories = ['All', 'Nouns', 'Verbs', 'Adjectives', 'Phrases', 'Others'];

  const wordTypeColors = {
    noun: '#FF9AA2',
    verb: '#FFDAC1',
    adjective: '#E2F0CB',
    phrase: '#B5EAD7',
    other: '#C7CEEA',
  };

  const [state, actions] = useAppState();
  const { error, setError, clearError } = useErrorHandler();
  const { showNotification } = useNotification();
  
  const {
    customTiles,
    addCustomTile,
    removeCustomTile,
    updateCustomTile,
    isAuthenticated
  } = usePersistentTiles();

  const getTilesSetter = useCallback(() => {
    return userSettings.tileType === 'default' ? setDefaultTiles : updateCustomTile;
  }, [userSettings.tileType, updateCustomTile]);

  const openModal = useCallback((modalType) => {
    setActiveModal(modalType);
  }, []);


  const closeModal = useCallback(() => {
    setActiveModal(null);
    setCurrentTile(null);
  }, []);

  const handleTileEdit = useCallback(() => {
    if (tileToEdit) {
      setCurrentTile(tileToEdit);
      openModal('editTile');
      setTileToEdit(null);
    }
  }, [tileToEdit, openModal]);

  const [lastClickTime, setLastClickTime] = useState(0);
  const COOLDOWN_TIME = 500; // milliseconds

  const handleTileClick = useCallback(async (id, sound, title) => {
    const speakPromise = speak(title);
    await handleAsyncError(speakPromise, 'Failed to play sound');
    actions.addPlayedWord(title);
  }, [actions]);

  const [isAddTileModalOpen, setIsAddTileModalOpen] = useState(false);

  const handleAddTile = useCallback((newTile) => {
    try {
      if (!newTile.title || !newTile.image) {
        showNotification('Title and image are required', 'error');
        return;
      }

      const tileToAdd = {
        ...newTile,
        id: Date.now(),
        createdAt: new Date().toISOString(),
        wordType: newTile.wordType || 'other'
      };

      addCustomTile(tileToAdd);
      showNotification('Tile added successfully', 'success');
      setIsAddTileModalOpen(false);

    } catch (error) {
      console.error('Error adding tile:', error);
      showNotification('Failed to add tile', 'error');
    }
  }, [addCustomTile, showNotification]);

  const handleDeleteTile = useCallback((id) => {
    setTileToDelete(id);
    setIsConfirmOpen(true);
  }, []);

  const confirmDelete = useCallback(() => {
    if (tileToDelete) {
      const setTiles = getTilesSetter();
      setTiles((prevTiles) => prevTiles.filter((tile) => tile.id !== tileToDelete));
      setIsConfirmOpen(false);
      setTileToDelete(null);
    }
  }, [tileToDelete, getTilesSetter]);

  const handleSaveTile = useCallback(async (updatedTile) => {
    try {
      if (activeTileType === 'default') {
        setDefaultTiles(prevTiles =>
          prevTiles.map(tile => tile.id === updatedTile.id ? updatedTile : tile)
        );
      } else {
        await updateCustomTile(updatedTile);
      }
      actions.setModal('edit', false);
    } catch (err) {
      setError('Failed to save tile');
    }
  }, [activeTileType, updateCustomTile, actions, setError]);

  const updateSettings = useCallback((newSettings) => {
    setUserSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
  }, []);

  useEffect(() => {
    setTiles(activeTileType === 'default' ? defaultTiles : customTiles);
  }, [activeTileType, defaultTiles, customTiles]);

  const handlePlayLastWords = useCallback(async () => {
    for (const word of lastPlayedWords) {
      try {
        await speak(word);
        await new Promise(resolve => setTimeout(resolve, 500));
      } catch (error) {
        console.error('Error speaking word:', error);
      }
    }
  }, [lastPlayedWords]);

  const handleClearLastWords = useCallback(() => {
    setLastPlayedWords([]);
  }, []);

  const [showStatistics, setShowStatistics] = useState(false);

  const handleOpenStatistics = useCallback(() => {
    setShowStatistics(true);
    setMenuOpen(false);
  }, []);

  const handleOpenMenu = useCallback(() => {
    console.log('Opening menu...'); // Debug log
    setIsSettingsOpen(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleLogout = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleModalClose = useCallback((modalType) => {
    actions.setModal(modalType, false);
    if (modalType === 'edit') {
      actions.setCurrentTile(null);
    }
  }, [actions]);

  const handleLibrarySelect = useCallback((tile) => {
    actions.setCurrentTile(tile);
    actions.setModal('edit', true);
  }, [actions]);

  // Add this useEffect to manage tiles based on activeTileType
  useEffect(() => {
    const currentTiles = state.activeTileType === 'default' ? 
      state.defaultTiles : 
      state.customTiles;
    
    actions.updateTiles(currentTiles || []);
  }, [state.activeTileType, state.defaultTiles, state.customTiles, actions]);

  // Initialize settings menu as closed
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleOpenSettings = useCallback(() => {
    setIsSettingsOpen(true);
    setMenuOpen(true);  // Sync with menu state
  }, []);

  const handleCloseSettings = useCallback(() => {
    console.log('Closing menu...'); // Debug log
    setIsSettingsOpen(false);
  }, []);

  const handleUpdateSettings = useCallback((newSettings) => {
    setUserSettings(prev => ({
      ...prev,
      ...newSettings
    }));
  }, []);

  const handleRemoveTile = useCallback((tileId) => {
    try {
      removeCustomTile(tileId);
      showNotification('Tile removed successfully', 'success');
    } catch (error) {
      console.error('Error removing tile:', error);
      showNotification('Failed to remove tile', 'error');
    }
  }, [removeCustomTile, showNotification]);

  const [isLoading, setIsLoading] = useState(false);

  // Wrap your app content
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <ErrorBoundary>
        <LibraryProvider>
          <AnimatePresence>
            {isLoading && (
              <LoadingOverlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    rotate: [0, 360]
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <img src="/images/brand-logo.png" alt="Loading" style={{ width: 50 }} />
                </motion.div>
              </LoadingOverlay>
            )}
          </AnimatePresence>
          
          <div className="app-container">
            {error && (
              <div className="error-message">
                {error}
                <button onClick={clearError}>Close</button>
              </div>
            )}
            
            <header className="app-header">
              <img src="/images/brand-logo.png" alt="Brand Logo" className="brand-logo" />
              {/* Make sure the button is clickable and visible */}
              <button 
                className="settings-button" 
                onClick={handleOpenMenu}
                aria-label="Open Settings"
                style={{ cursor: 'pointer', zIndex: 1001 }}
              >
                <FaBars />
              </button>
            </header>
            <main className="main-content">
              <LastPlayedWords 
                words={state.lastPlayedWords}
                onPlay={handlePlayLastWords}
                onClear={() => actions.clearPlayedWords()}
                className="last-played-words"
              />
              
              <div className="tile-grid-container">
                <TileGrid
                  tiles={activeTileType === 'default' ? defaultTiles : customTiles}
                  onTileClick={handleTileClick}
                  onTileEdit={(tile) => actions.setTileToEdit(tile)}
                  onTileRemove={handleRemoveTile}
                  columns={userSettings.gridColumns}
                  wordTypeColors={wordTypeColors}
                  onAddTile={() => setIsAddTileModalOpen(true)}
                />
              </div>
            </main>
            <div className="category-bar">
              <CategoryButtons
                categories={categories}
                activeCategory={activeCategory}
                onCategoryChange={(category) => actions.setActiveCategory(category)}
              />
            </div>
            <footer className="bottom-bar">
              <button
                className={`tab-button ${activeTileType === 'default' ? 'active' : ''}`}
                onClick={() => setActiveTileType('default')}
              >
                Default
              </button>
              <button
                className={`tab-button ${activeTileType === 'custom' ? 'active' : ''}`}
                onClick={() => setActiveTileType('custom')}
              >
                Custom
              </button>
            </footer>
            <SettingsMenu
              isOpen={isSettingsOpen}
              onClose={handleCloseSettings}
              settings={userSettings}
              onUpdateSettings={handleUpdateSettings}
              user={user}
              onLogout={handleLogout}
            />
            {showStatistics && (
              <div className="statistics-placeholder">Statistics placeholder</div>
            )}
            <Modal
              modals={state.modals}
              currentTile={state.currentTile}
              onClose={handleModalClose}
              onSave={handleSaveTile}
              onConfirmDelete={() => handleDeleteTile(state.tileToDelete)}
              onAddTile={handleAddTile}
              onOpenLibrary={handleLibrarySelect}
              confirmMessage="Are you sure you want to delete this tile?"
            />
            {process.env.NODE_ENV === 'development' && (
              <div style={{ position: 'fixed', bottom: 10, left: 10 }}>
                Settings Open: {isSettingsOpen ? 'Yes' : 'No'}
              </div>
            )}
            <AddTileModal
              isOpen={isAddTileModalOpen}
              onClose={() => setIsAddTileModalOpen(false)}
              onAdd={handleAddTile}
              initialData={null}
            />
          </div>
        </LibraryProvider>
      </ErrorBoundary>
    </LoadingContext.Provider>
  );
}

export default App;
