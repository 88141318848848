import React, { useState, useEffect } from 'react';
import '../styles/shared/modal.css';
import PropTypes from 'prop-types';

const Modal = ({ isOpen, onClose, onSave, tile, onOpenLibrary }) => {
  const [editedTile, setEditedTile] = useState(tile || {});

  useEffect(() => {
    setEditedTile(tile || {});
  }, [tile]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Edit Tile</h2>
        <input
          className="modal-input"
          type="text"
          name="title"
          value={editedTile.title || ''}
          onChange={(e) => setEditedTile(prev => ({ ...prev, title: e.target.value }))}
          placeholder="Tile Title"
        />
        <div className="modal-buttons">
          <button 
            className="modal-button modal-button-secondary" 
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className="modal-button modal-button-primary" 
            onClick={() => onSave(editedTile)}
          >
            Save
          </button>
          <button 
            className="modal-button modal-button-primary" 
            onClick={onOpenLibrary}
          >
            Open Library
          </button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tile: PropTypes.object,
  onOpenLibrary: PropTypes.func.isRequired,
};

export default Modal;
