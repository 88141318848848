import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
`;

const TileCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
`;

const TileImage = styled.img`
  width: 100%;
  height: 140px;
  object-fit: cover;
`;

const TileTitle = styled.div`
  padding: 10px;
  text-align: center;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const LibraryModal = ({ isOpen, onClose, onSelect, defaultTiles }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTiles = defaultTiles.filter((tile) =>
    tile.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalTitle>Tile Library</ModalTitle>
        <SearchInput
          type="text"
          placeholder="Search tiles"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <TileGrid>
          {filteredTiles.map((tile) => (
            <TileCard
              key={tile.id}
              onClick={() => {
                onSelect(tile);
                onClose();
              }}
            >
              <TileImage src={tile.image} alt={tile.title} />
              <TileTitle>{tile.title}</TileTitle>
            </TileCard>
          ))}
        </TileGrid>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LibraryModal;
