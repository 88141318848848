import { useState, useEffect } from 'react';

const CUSTOM_TILES_KEY = 'customTiles';

export const usePersistentTiles = () => {
  // Initialize state from localStorage
  const [customTiles, setCustomTiles] = useState(() => {
    const savedTiles = localStorage.getItem(CUSTOM_TILES_KEY);
    return savedTiles ? JSON.parse(savedTiles) : [];
  });

  // Save to localStorage whenever customTiles changes
  useEffect(() => {
    localStorage.setItem(CUSTOM_TILES_KEY, JSON.stringify(customTiles));
  }, [customTiles]);

  const addCustomTile = (newTile) => {
    setCustomTiles(prevTiles => {
      const updatedTiles = [...prevTiles, { ...newTile, id: Date.now() }];
      return updatedTiles;
    });
  };

  const removeCustomTile = (tileId) => {
    setCustomTiles(prevTiles => {
      const updatedTiles = prevTiles.filter(tile => tile.id !== tileId);
      return updatedTiles;
    });
  };

  const updateCustomTile = (updatedTile) => {
    setCustomTiles(prevTiles => {
      const updatedTiles = prevTiles.map(tile => 
        tile.id === updatedTile.id ? updatedTile : tile
      );
      return updatedTiles;
    });
  };

  return {
    customTiles,
    addCustomTile,
    removeCustomTile,
    updateCustomTile
  };
};
