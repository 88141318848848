import React, { memo } from 'react';
import styled from 'styled-components';
import Tile from './Tile';
import { FaPlus } from 'react-icons/fa';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns || 4}, 1fr);
  gap: 1rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  
  /* Add smooth transitions for better UX */
  transition: all 0.3s ease;
  
  /* Add responsive design */
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 0.5rem;
    padding: 0.5rem;
  }
`;

const AddTileButton = styled.div`
  width: 150px; // medium tile size
  height: 150px; // medium tile size
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px dashed #4CAF50;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: white;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }

  svg {
    font-size: 24px;
    color: #4CAF50;
    margin-bottom: 10px;
    max-height: 60%;
  }

  span {
    color: #4CAF50;
    font-size: 14px;
    text-align: center;
    margin: 5px 0 0;
  }

  @media (max-width: 768px) {
    span {
      font-size: 10px;
    }
  }
`;

const TileGrid = ({ 
  tiles = [], 
  onTileClick, 
  onTileEdit, 
  onTileRemove, 
  columns = 4,
  wordTypeColors = {
    noun: '#FF9AA2',
    verb: '#FFDAC1',
    adjective: '#E2F0CB',
    phrase: '#B5EAD7',
    other: '#C7CEEA',
  },
  onAddTile
}) => {
  return (
    <GridContainer columns={columns} role="grid" aria-label="Communication Tiles">
      {tiles?.map((tile) => (
        <Tile
          key={tile.id}
          {...tile}
          onClick={() => onTileClick(tile.id, tile.sound, tile.title)}
          onEdit={() => onTileEdit(tile)}
          onDelete={() => onTileRemove(tile.id)}
          borderColor={wordTypeColors[tile.wordType] || wordTypeColors.other}
        />
      ))}
      <AddTileButton 
        onClick={onAddTile}
        role="button"
        aria-label="Add new tile"
        className="tile medium" // Add the same class as other tiles
      >
        <FaPlus aria-hidden="true" />
        <span className="tile-title">Add New</span>
      </AddTileButton>
    </GridContainer>
  );
};

export default memo(TileGrid);
