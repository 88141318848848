import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUpload } from 'react-icons/fa';
import '../styles/shared/modal.css';
import PropTypes from 'prop-types';

const ModalContent = styled.div`
  padding: 20px;
  max-width: 500px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled.label`
  font-weight: 500;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  
  &.cancel {
    background: #f5f5f5;
    &:hover {
      background: #e5e5e5;
    }
  }
  
  &.submit {
    background: #4CAF50;
    color: white;
    &:hover {
      background: #45a049;
    }
  }
`;

const AddTileModal = ({ isOpen, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    title: '',
    image: '',
    wordType: 'noun',
    sound: '' // Optional: for custom sounds
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Create new tile object
    const newTile = {
      id: Date.now(), // Simple way to generate unique ID
      ...formData
    };

    onAdd(newTile);
    onClose();
    
    // Reset form
    setFormData({
      title: '',
      image: '',
      wordType: 'noun',
      sound: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <ModalContent>
        <h2>Add New Tile</h2>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Label htmlFor="title">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </InputGroup>

          <InputGroup>
            <Label htmlFor="image">Image URL</Label>
            <Input
              type="text"
              id="image"
              name="image"
              value={formData.image}
              onChange={handleChange}
              placeholder="https://example.com/image.jpg"
              required
            />
          </InputGroup>

          <InputGroup>
            <Label htmlFor="wordType">Word Type</Label>
            <Select
              id="wordType"
              name="wordType"
              value={formData.wordType}
              onChange={handleChange}
            >
              <option value="noun">Noun</option>
              <option value="verb">Verb</option>
              <option value="adjective">Adjective</option>
              <option value="phrase">Phrase</option>
              <option value="other">Other</option>
            </Select>
          </InputGroup>

          <ButtonGroup>
            <Button 
              type="button" 
              className="cancel"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              className="submit"
            >
              Add Tile
            </Button>
          </ButtonGroup>
        </Form>
      </ModalContent>
    </div>
  );
};

AddTileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddTileModal;
