export class AppError extends Error {
  constructor(message, code, details = {}) {
    super(message);
    this.code = code;
    this.details = details;
    this.name = 'AppError';
  }
}

export const errorCodes = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  AUTH_ERROR: 'AUTH_ERROR',
  STORAGE_ERROR: 'STORAGE_ERROR',
  TILE_ERROR: 'TILE_ERROR',
  SPEECH_ERROR: 'SPEECH_ERROR',
  AUTH_REQUIRED: 'AUTH_REQUIRED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  INVALID_TILE: 'INVALID_TILE'
};

export const handleAsyncError = async (promise, errorMessage) => {
  try {
    return await promise;
  } catch (error) {
    console.error(error);
    throw new AppError(
      errorMessage || error.message,
      error.code || errorCodes.NETWORK_ERROR,
      { originalError: error }
    );
  }
};

export const isNetworkError = (error) => {
  return !navigator.onLine || 
    error.name === 'NetworkError' || 
    error.message.includes('network');
};

export const handleTileError = (error) => {
  if (error.code === errorCodes.AUTH_REQUIRED) {
    // Handle authentication errors
    return {
      message: 'Please log in to manage tiles',
      severity: 'warning'
    };
  }
  
  if (error.code === errorCodes.UNAUTHORIZED) {
    return {
      message: 'You do not have permission to modify this tile',
      severity: 'error'
    };
  }

  // Default error handling
  return {
    message: 'An error occurred while managing tiles',
    severity: 'error'
  };
};
