import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaTimes, FaUser, FaMoon, FaSun, FaVolumeUp, FaMars, FaVenus } from 'react-icons/fa';

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 300px;
  height: 100vh;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #333;
  
  &:hover {
    color: #666;
  }
`;

const Section = styled.div`
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h3`
  color: #2c3e50;
  font-size: 1.1rem;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #4CAF50;
  display: inline-block;
`;

const SettingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666;
`;

const Input = styled.input`
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Slider = styled.input`
  width: 100%;
  margin: 10px 0;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  
  &:hover {
    background: #d32f2f;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
`;

const VoiceToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
`;

const ToggleTrack = styled.div`
  width: 60px;
  height: 30px;
  background: ${props => props.isChecked ? '#4CAF50' : '#666'};
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  
  svg {
    color: white;
    font-size: 14px;
    z-index: 1;
  }
`;

const ToggleThumb = styled.div`
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: ${props => props.isChecked ? '32px' : '2px'};
  transition: left 0.3s ease;
`;

const ComingSoonBadge = styled.span`
  background: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
`;

const SettingButton = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: 2px solid #4CAF50;
  background: ${props => props.active ? '#4CAF50' : 'transparent'};
  color: ${props => props.active ? 'white' : '#4CAF50'};
  transition: all 0.2s ease;
  font-weight: 500;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(76, 175, 80, 0.2);
    background: #4CAF50;
    color: white;
  }

  &:active {
    transform: translateY(0);
  }
`;

const AnimatedIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${props => props.active ? '#e8f5e9' : 'transparent'};
  margin-right: 12px;
  transition: all 0.2s ease;

  svg {
    color: #4CAF50;
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

const SettingsMenu = ({ isOpen, onClose, settings, onUpdateSettings, user, onLogout }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both the menu and the settings button
      if (menuRef.current && 
          !menuRef.current.contains(event.target) && 
          !event.target.closest('.settings-button')) {
        onClose();
      }
    };

    // Only add the listener if the menu is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Add escape key handler
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  const handleVoiceToggle = () => {
    onUpdateSettings({ 
      voiceGender: settings.voiceGender === 'male' ? 'female' : 'male' 
    });
    // Show "Coming Soon" notification or handle as needed
  };

  return (
    <>
      <MenuOverlay isOpen={isOpen} />
      <MenuContainer ref={menuRef} isOpen={isOpen}>
        <Header>
          <h2>Settings</h2>
          <CloseButton onClick={onClose} aria-label="Close settings">
            <FaTimes />
          </CloseButton>
        </Header>

        {user && (
          <UserInfo>
            <FaUser />
            <span>{user.name}</span>
          </UserInfo>
        )}

        <Section>
          <SectionTitle>Display</SectionTitle>
          <SettingRow>
            <Label>
              Grid Columns
            </Label>
            <Input
              type="number"
              value={settings.gridColumns}
              onChange={(e) => onUpdateSettings({ gridColumns: Number(e.target.value) })}
              min="2"
              max="6"
            />
          </SettingRow>
          <SettingRow>
            <Label>
              <AnimatedIcon active={settings.theme === 'dark'}>
                {settings.theme === 'dark' ? <FaMoon /> : <FaSun />}
              </AnimatedIcon>
              Theme
            </Label>
            <SettingButton 
              active={settings.theme === 'dark'}
              onClick={() => onUpdateSettings({ theme: settings.theme === 'dark' ? 'light' : 'dark' })}
            >
              {settings.theme === 'dark' ? 'Dark' : 'Light'}
            </SettingButton>
          </SettingRow>
        </Section>

        <Section>
          <SectionTitle>Voice Settings</SectionTitle>
          <VoiceToggle>
            <Label>
              <FaMars /> Male / <FaVenus /> Female
            </Label>
            <ToggleTrack 
              isChecked={settings.voiceGender === 'female'}
              onClick={handleVoiceToggle}
              role="switch"
              aria-checked={settings.voiceGender === 'female'}
            >
              <FaMars />
              <FaVenus />
              <ToggleThumb isChecked={settings.voiceGender === 'female'} />
            </ToggleTrack>
            <ComingSoonBadge>Coming Soon</ComingSoonBadge>
          </VoiceToggle>
        </Section>

        {user && (
          <Button onClick={onLogout}>
            Logout
          </Button>
        )}
      </MenuContainer>
    </>
  );
};

export default SettingsMenu;
