import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

const CategoryButtons = ({ categories, activeCategory, onCategoryChange }) => {
  return (
    <StyledToggleButtonGroup
      value={activeCategory}
      exclusive
      onChange={(event, newCategory) => {
        if (newCategory !== null) {
          onCategoryChange(newCategory);
        }
      }}
    >
      {categories.map((category) => (
        <ToggleButton key={category} value={category}>
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default CategoryButtons;
