import { initialState } from '../hooks/useAppState';

export const ActionTypes = {
  SET_ACTIVE_TILE_TYPE: 'SET_ACTIVE_TILE_TYPE',
  SET_LAST_CLICK_TIME: 'SET_LAST_CLICK_TIME',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  SET_CURRENT_TILE: 'SET_CURRENT_TILE',
  SET_MENU_OPEN: 'SET_MENU_OPEN',
  SET_ACTIVE_CATEGORY: 'SET_ACTIVE_CATEGORY',
  ADD_PLAYED_WORD: 'ADD_PLAYED_WORD',
  CLEAR_PLAYED_WORDS: 'CLEAR_PLAYED_WORDS',
  UPDATE_TILES: 'UPDATE_TILES',
  SET_MODAL: 'SET_MODAL',
  SET_TILE_TO_DELETE: 'SET_TILE_TO_DELETE',
  SET_TILE_TO_EDIT: 'SET_TILE_TO_EDIT',
  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR'
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_TILE_TYPE:
      return { ...state, activeTileType: action.payload };
    case ActionTypes.SET_LAST_CLICK_TIME:
      return { ...state, lastClickTime: action.payload };
    case ActionTypes.UPDATE_SETTINGS:
      return { ...state, userSettings: action.payload };
    case ActionTypes.SET_CURRENT_TILE:
      return { ...state, currentTile: action.payload };
    case ActionTypes.SET_MENU_OPEN:
      return { ...state, menuOpen: action.payload };
    case ActionTypes.SET_ACTIVE_CATEGORY:
      return { ...state, activeCategory: action.payload };
    case ActionTypes.ADD_PLAYED_WORD:
      return { 
        ...state, 
        lastPlayedWords: [...state.lastPlayedWords, action.payload].slice(-5)
      };
    case ActionTypes.CLEAR_PLAYED_WORDS:
      return { ...state, lastPlayedWords: [] };
    case ActionTypes.UPDATE_TILES:
      return { ...state, tiles: action.payload };
    case ActionTypes.SET_MODAL:
      return { 
        ...state, 
        modals: {
          ...state.modals,
          [action.payload.modalType]: action.payload.isOpen
        }
      };
    case ActionTypes.SET_TILE_TO_DELETE:
      return { ...state, tileToDelete: action.payload };
    case ActionTypes.SET_TILE_TO_EDIT:
      return { ...state, tileToEdit: action.payload };
    case ActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    case ActionTypes.CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
