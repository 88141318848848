import React, { createContext, useState, useContext, useEffect } from 'react';

const LibraryContext = createContext();

export const useLibrary = () => useContext(LibraryContext);

export const LibraryProvider = ({ children }) => {
  const [library, setLibrary] = useState([]);

  useEffect(() => {
    // Load library from localStorage on mount
    const savedLibrary = localStorage.getItem('wordLibrary');
    if (savedLibrary) {
      setLibrary(JSON.parse(savedLibrary));
    }
  }, []);

  const addToLibrary = (word) => {
    setLibrary(prevLibrary => {
      const newLibrary = [...prevLibrary, word];
      localStorage.setItem('wordLibrary', JSON.stringify(newLibrary));
      return newLibrary;
    });
  };

  const removeFromLibrary = (wordId) => {
    setLibrary(prevLibrary => {
      const newLibrary = prevLibrary.filter(word => word.id !== wordId);
      localStorage.setItem('wordLibrary', JSON.stringify(newLibrary));
      return newLibrary;
    });
  };

  return (
    <LibraryContext.Provider value={{ library, addToLibrary, removeFromLibrary }}>
      {children}
    </LibraryContext.Provider>
  );
};
