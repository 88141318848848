import { useReducer, useCallback } from 'react';
import { appReducer, ActionTypes } from '../reducers/appReducer';

// Define initial state
export const initialState = {
  tiles: [], // Initialize empty array
  defaultTiles: [
    { id: 1, title: 'Play', image: '/images/play.webp' },
    { id: 2, title: 'Mam', image: '/images/mam.webp' },
    { id: 3, title: 'Dad', image: '/images/dad.webp' },
    { id: 4, title: 'Love', image: '/images/love.webp' },
    { id: 5, title: 'Food', image: '/images/food.webp' },
    { id: 6, title: 'More', image: '/images/more.webp' },
    { id: 7, title: 'Drink', image: '/images/drink.webp' },
    { id: 8, title: 'Sleep', image: '/images/sleep.webp' },
    { id: 9, title: 'Help', image: '/images/help.webp' },
    { id: 10, title: 'Happy', image: '/images/happy.webp' },
  ],
  customTiles: [],
  lastPlayedWords: [],
  lastClickTime: 0,
  modals: {
    add: false,
    edit: false,
    confirm: false,
    library: false
  },
  userSettings: {
    gridColumns: 4,
    tileSize: 'medium',
    theme: 'light',
    language: 'English',
    voiceId: 'male_voice_id',
    tileType: 'default'
  },
  currentTile: null,
  tileToDelete: null,
  tileToEdit: null,
  activeTileType: 'default',
  activeCategory: 'all'
};

// Add selector after initial state definition
const getUserSettings = (state) => state.userSettings || initialState.userSettings;

export function useAppState() {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const actions = {
    setActiveTileType: useCallback((tileType) => {
      dispatch({ type: ActionTypes.SET_ACTIVE_TILE_TYPE, payload: tileType });
    }, []),

    setLastClickTime: useCallback((time) => {
      dispatch({ type: ActionTypes.SET_LAST_CLICK_TIME, payload: time });
    }, []),

    updateSettings: useCallback((settings) => {
      dispatch({ 
        type: ActionTypes.UPDATE_SETTINGS, 
        payload: { ...getUserSettings(state), ...settings }
      });
    }, [state]),

    setCurrentTile: useCallback((tile) => {
      dispatch({ type: ActionTypes.SET_CURRENT_TILE, payload: tile });
    }, []),

    setMenuOpen: useCallback((isOpen) => {
      dispatch({ type: ActionTypes.SET_MENU_OPEN, payload: isOpen });
    }, []),

    setActiveCategory: useCallback((category) => {
      dispatch({ type: ActionTypes.SET_ACTIVE_CATEGORY, payload: category });
    }, []),

    addPlayedWord: useCallback((word) => {
      dispatch({ type: ActionTypes.ADD_PLAYED_WORD, payload: word });
    }, []),

    clearPlayedWords: useCallback(() => {
      dispatch({ type: ActionTypes.CLEAR_PLAYED_WORDS });
    }, []),

    updateTiles: useCallback((tiles) => {
      dispatch({ type: ActionTypes.UPDATE_TILES, payload: tiles });
    }, []),

    setModal: useCallback((modalType, isOpen) => {
      dispatch({ 
        type: ActionTypes.SET_MODAL, 
        payload: { modalType, isOpen } 
      });
    }, []),

    setTileToDelete: useCallback((tileId) => {
      dispatch({ type: ActionTypes.SET_TILE_TO_DELETE, payload: tileId });
    }, []),

    setTileToEdit: useCallback((tile) => {
      dispatch({ type: ActionTypes.SET_TILE_TO_EDIT, payload: tile });
    }, []),

    setError: useCallback((error) => {
      dispatch({ type: ActionTypes.SET_ERROR, payload: error });
      setTimeout(() => {
        dispatch({ type: ActionTypes.CLEAR_ERROR });
      }, 5000);
    }, [])
  };

  return [state, actions];
}
