import { useState, useCallback } from 'react';

export const useErrorHandler = (initialError = null) => {
  const [error, setError] = useState(initialError);

  const handleError = useCallback((error) => {
    if (typeof error === 'string') {
      setError({ message: error });
    } else if (error instanceof Error) {
      setError(error);
    } else {
      setError(new Error('An unknown error occurred'));
    }
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    error,
    setError: handleError,
    clearError
  };
};
