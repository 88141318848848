// First, all imports
import { handleAsyncError } from './errorHandling';
import { AppError, errorCodes } from './errorHandling';

// Check if speech synthesis is supported
const isSpeechSynthesisSupported = typeof window !== 'undefined' && 'speechSynthesis' in window;

let speechSynthesis;
if (isSpeechSynthesisSupported) {
  speechSynthesis = window.speechSynthesis;
}

export const speak = async (text, voice = null, rate = 1, pitch = 1) => {
  try {
    if (!isSpeechSynthesisSupported) {
      throw new AppError('Speech synthesis not available', errorCodes.SPEECH_ERROR);
    }

    const utterance = new SpeechSynthesisUtterance(text);
    if (voice) utterance.voice = voice;
    utterance.rate = rate;
    utterance.pitch = pitch;

    return new Promise((resolve, reject) => {
      utterance.onend = () => resolve();
      utterance.onerror = (error) => reject(error);
      speechSynthesis.speak(utterance);
    });
  } catch (error) {
    throw new AppError(
      'Failed to speak text',
      errorCodes.SPEECH_ERROR,
      { originalError: error }
    );
  }
};

export const getVoices = async () => {
  if (!isSpeechSynthesisSupported) {
    throw new AppError('Speech synthesis not available', errorCodes.SPEECH_ERROR);
  }

  return handleAsyncError(
    new Promise((resolve) => {
      let voices = speechSynthesis.getVoices();
      if (voices.length) {
        resolve(voices);
      } else {
        speechSynthesis.onvoiceschanged = () => {
          voices = speechSynthesis.getVoices();
          resolve(voices);
        };
      }
    }),
    'Failed to get voices'
  );
};

// Function to stop all speech
export const stopSpeaking = () => {
  if (isSpeechSynthesisSupported) {
    window.speechSynthesis.cancel();
  }
};
