import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaEdit, FaTrash } from 'react-icons/fa';

const StyledTile = styled.div`
  width: ${props => props.size === 'small' ? '80px' : props.size === 'large' ? '120px' : '100px'};
  height: ${props => props.size === 'small' ? '80px' : props.size === 'large' ? '120px' : '100px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  position: relative;
  border: 3px solid ${props => props.borderColor || '#007bff'};
  border-radius: 8px;
  background-color: white;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover, &:active {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const TileImage = styled.img`
  width: 80%;
  height: 60%;
  object-fit: contain;
  margin-bottom: 10px;
`;

const TileTitle = styled.p`
  margin: 0;
  text-align: center;
  font-size: 14px;
  word-break: break-word;
`;

const ActionButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.8);
  }

  ${StyledTile}:hover & {
    opacity: 0.7;
  }
`;

const EditButton = styled(ActionButton)`
  top: 5px;
  right: 5px;
`;

const DeleteButton = styled(ActionButton)`
  bottom: 5px;
  right: 5px;
`;

const AgeIndicator = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #6c757d;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75rem;
`;

const Tile = ({ 
  id,
  title,
  image,
  size = 'medium',
  borderColor,
  onClick,
  onEdit,
  onDelete,
  disabled,
  ageGroup 
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled) {
      onClick();
    }
  };

  return (
    <StyledTile 
      size={size} 
      borderColor={borderColor}
      onClick={handleClick}
    >
      {ageGroup && <AgeIndicator>{ageGroup}</AgeIndicator>}
      <TileImage
        src={image || '/placeholder-image.png'}
        alt={title}
      />
      <TileTitle>{title}</TileTitle>
      <EditButton
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <FaEdit />
      </EditButton>
      <DeleteButton
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <FaTrash />
      </DeleteButton>
    </StyledTile>
  );
};

Tile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  borderColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  ageGroup: PropTypes.string
};

export default Tile;
